import React from 'react';
import ReactDOM from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import Median from 'median-js-bridge';
import { Provider } from 'react-redux';
import {
  createBrowserRouter,
  RouterProvider
} from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import './index.css';
import store from './app/store';
import routes from './routes';
import { getBasename } from './utils';

const router = createBrowserRouter(routes, {
  basename: getBasename(),
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
async function pickMultipleContacts(): Promise<void> {
  const getCsrfToken = (): string => document.cookie.replace(/(?:(?:^|.*;\s*)XSRF-TOKEN\s*=\s*([^;]*).*$)|^.*$/, '$1') || '';
  const result = await Median.contacts.pickContact({
    multiple: true,
  });
  if (result && result.error) {
    // eslint-disable-next-line no-alert
    window.alert(`Error ${result.error}`);
  } else if (result && (result.contacts || result.contact)) {
    const contacts = result.contacts ? result.contacts : [result.contact];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const obj = contacts.map((r: any) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const phones = (r.phoneNumbers || []).map((p: any) => p.phoneNumber).filter((p: any) => p);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const emails = (r.emailAddresses || []).map((p: any) => p.emailAddress).filter((p: any) => p);
      const contact = {
        firstName: r.givenName || '',
        lastName: r.familyName || '',
        phoneNumber: phones ? phones[0] : '',
        email: emails ? emails[0] : '',
      };
      return contact;
    });
    await fetch(`/api/${window.SITE_ID}/PlayerContact/ContactMobile`, {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-XSRF-TOKEN': getCsrfToken(),
      },
      body: JSON.stringify({ contacts: obj }),
    });
    window.dispatchEvent(new CustomEvent('reload-contacts', {}));
  }
}
window.pickMultipleContacts = pickMultipleContacts;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <CssBaseline />
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.debug);
